/*
 * Copyright (C) Sitevision AB 2002-2023, all rights reserved
 *
 * dialog utils (i.e. setup modal backbone dialogs)
 *
 * @author micke
 * @deprecated
 *
 * Use the envision-based modalUtil instead of this dialogUtil!
 */
import sv from '@sv/core';
import $ from '@sv/jquery';
import { i18n, TemplateUtil as templateUtil } from '@sv/util';

const pTmpl = '<p><%= text %></p>';

const btnTmpl =
  '<button <%= attributes %> class="btn <%= classNames %> "><%= text %></button>';

const textareaTmpl =
  '<textarea class="ui-widget-content ui-corner-all sv-eventfeed-input sv-eventfeed-message-input">' +
  '<%= value %></textarea>';

const inputTmpl =
  '<input type="text" value="<%= value %>" ' +
  'class="ui-widget-content ui-corner-all sv-eventfeed-input">';

const formTmpl =
  '<form class="form-horizontal">' +
  '<div class="control-group">' +
  '<label class="control-label"><%= label %></label>' +
  '<div class="controls"><%= input %></div>' +
  '</div>' +
  '</form>';

const errorTmpl =
  '<div class="alert alert-error" style="display: none">' +
  '<h4 class="alert-title"><%= errorTitle %><h4>' +
  '<p class="alert-message"><%= errorMsg %></p>' +
  '</div>';

const modalTmpl =
  '<div class="modal hide fade bootstrap">' +
  '<div class="modal-dialog">' +
  '<div class="modal-content">' +
  '<div class="modal-header">' +
  '<button class="close" type="button" data-dismiss="modal" aria-label="<%= closeModal %>">&times;</button>' +
  '<h3><%= title %></h3>' +
  '</div>' +
  '<div class="modal-body">' +
  '<%= titleHelp %>' +
  '<%= errorContent %>' +
  '<%= modalBody %>' +
  '</div>' +
  '<div class="modal-footer"><%= footerButtons %></div>' +
  '</div>' +
  '</div>' +
  '</div>';

//===== HELPERS =====//

function getText(key, args) {
  return i18n.getText('util.dialogutil', key, args);
}

function getModal(options) {
  var _options = options;
  // Default values
  _options = $.extend(
    {
      title: '',
      modalBody: '',
      footerButtons: '',
      titleHelp: '',
      errorContent: '',
      closeModal: getText('closeModal'),
    },
    _options
  );

  if (typeof _options.modalBody === 'string') {
    return $(templateUtil.template(modalTmpl, _options));
  }

  // If body is passed in as an object we need to append it.
  _options.modalBody = '';
  var $modal = $(templateUtil.template(modalTmpl, _options));
  $modal.find('.modal-body').append(options.modalBody);

  return $modal;
}

function getOkCancelButtons() {
  var cancelButton, okButton;
  okButton = templateUtil.template(btnTmpl, {
    classNames: 'btn-primary',
    attributes: 'data-dismiss="modal" data-ok="true"',
    text: getText('ok'),
  });
  cancelButton = templateUtil.template(btnTmpl, {
    classNames: '',
    attributes: 'data-dismiss="modal" data-ok="false"',
    text: getText('cancel'),
  });
  return okButton + cancelButton;
}

function addShowHideEventListeners($modal, hideOnClose) {
  $modal.on('shown.bs.modal', function () {
    var $focusEl = $modal.find('[data-fn-request-focus]');
    if ($focusEl.length) {
      $focusEl.first().trigger('focus');
    } else {
      focusFirst($modal);
    }
    $(document.body).addClass('modal-open');
  });
  $modal.on('hidden.bs.modal', function () {
    hideOnClose !== false && $modal.remove();
    $(document.body).removeClass('modal-open');
  });
}

function focusFirst($modal) {
  var keyboardFocusableElements = Array.from(
    $modal.find(
      'a[href], area[href], button, *[contenteditable], embed, input:not([type=hidden]), object, textarea, select, details,[tabindex]:not([tabindex="-1"])',
    ),
  ).filter(
    (el) =>
      !el.classList.contains('close') && // Filter out close button in modal header
      !el.classList.contains('jcrop-keymgr') && // Moving focus visually seems to be inconsistent
      !el.hasAttribute('disabled') &&
      !el.getAttribute('aria-hidden') &&
      el.getAttribute('tabindex') !== '-1' &&
      !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length) &&
      window.getComputedStyle(el).visibility !== 'hidden',
  );

  if (keyboardFocusableElements.length > 0) {
    keyboardFocusableElements[0].focus();
  }
}

//===== DIALOGS =====//

function showMessageDialog(title, text, buttonText) {
  var $modal, defaultButton;

  defaultButton = templateUtil.template(btnTmpl, {
    classNames: 'btn-primary',
    attributes: 'data-dismiss="modal"',
    text: buttonText || 'Close',
  });

  $modal = getModal({
    title: templateUtil.htmlEncode(title),
    modalBody: text,
    footerButtons: defaultButton,
  });

  addShowHideEventListeners($modal);

  $modal.modal();

  return $modal;
}

function showConfirmDialog(title, text, callback) {
  var $modal;

  $modal = getModal({
    title: templateUtil.htmlEncode(title),
    modalBody: templateUtil.htmlEncode(text),
    footerButtons: getOkCancelButtons(),
  });

  $modal.on('click', '.modal-footer .btn', function () {
    callback(!!$(this).data('ok'));
  });

  addShowHideEventListeners($modal);

  $modal.modal();

  return $modal;
}

function showInputDialog(title, text, expanding, value, callback) {
  var $modal, form, input;

  input = templateUtil.template(expanding ? textareaTmpl : inputTmpl, {
    value: value || '',
  });

  form = templateUtil.template(formTmpl, { input: input, label: text || '' });

  $modal = getModal({
    title: templateUtil.htmlEncode(title),
    modalBody: form,
    footerButtons: getOkCancelButtons(),
  });

  $modal.on('click', '.modal-footer .btn', function () {
    if ($(this).data('ok')) {
      callback({
        result: true,
        text: $modal.find('.sv-eventfeed-input').val(),
      });
    } else {
      callback({ result: false });
    }
  });

  $modal.on('submit', 'form', function (e) {
    e.preventDefault();
    $modal.modal('hide');
    callback({
      result: true,
      text: $modal.find('.sv-eventfeed-input').val(),
    });
  });

  addShowHideEventListeners($modal);

  $modal.modal();

  return $modal;
}

function showDialog(options) {
  var $modal, $modalFooter, titleHelp, errorContent;

  titleHelp = options.titleHelp
    ? templateUtil.template(pTmpl, { text: options.titleHelp })
    : '';

  errorContent =
    options.errorTitle && options.errorMsg
      ? templateUtil.template(errorTmpl, {
          errorTitle: options.errorTitle,
          errorMsg: options.errorMsg,
        })
      : '';

  $modal = getModal({
    title: templateUtil.htmlEncode(options.title),
    modalBody: options.body,
    titleHelp: titleHelp,
    errorContent: errorContent,
  });

  $modalFooter = $modal.find('.modal-footer');

  $.each(options.buttons, function (index, elem) {
    var $button, classNames, attributes;

    attributes = elem.skipDismiss ? '' : ' data-dismiss="modal" ';
    classNames = elem.primary ? ' btn-primary ' : '';
    classNames += elem.danger ? ' btn-danger ' : '';

    $button = $(
      templateUtil.template(btnTmpl, {
        classNames: classNames,
        text: elem.text,
        attributes: attributes,
      })
    );

    if (elem.callback) {
      $button.on('click', elem.callback);
    }

    $modalFooter.append($button);
  });

  if (options.shownCallback && typeof options.shownCallback === 'function') {
    $modal.on('shown.bs.modal', function (e) {
      options.shownCallback.call(this, e);
    });
  }

  if (
    options.removeOnHide !== false &&
    options.hiddenCallback &&
    typeof options.hiddenCallback === 'function'
  ) {
    $modal.on('hidden.bs.modal', function (e) {
      options.hiddenCallback.call(this, e);
    });
  }

  addShowHideEventListeners($modal, options.removeOnHide);

  $modal.modal();

  return $modal;
}

sv.DialogUtil = {
  showInputDialog,
  showConfirmDialog,
  showMessageDialog,
  showDialog,
};
